.custom-input-box {
    position: relative;
    height: 60px;
    margin: 25px 10px;
    display: flex;
}
.custom-input-box input {
    height: 100%;
    width: 65%;
    background: #fff;
    color: #333;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    border-radius: 6px;
    padding: 0 50px 0 16px;
    border: 2px solid #a6a6a6;
}
.custom-input-box i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.custom-input-box label {
    width: 35%;
    font-size: 18px;
    line-height: 60px;
    font-weight: 500;
    background: #fff;
    padding-right: 10px;
    color: #a6a6a6;
    pointer-events: none;
    text-align: right;
    transition: all 0.3s ease;
}
.custom-input-box input:focus {
    border-color: #4070F4;
}
.custom-input-box i {
    right: 15px;
}
.custom-alert {
    display: flex;
    align-items: center;
    margin-top: -10px;
    width: 100%;
    height: 100%;
}
.custom-alert i {
    display: inline-block;
    margin-left: 35%;
    margin-right: 10px;
    color: #D93025;
    width: 24px;
    height: 24px;
}
.custom-alert span {
    color: #a6a6a6;
    font-size: 18px;
    font-weight: 500;
}