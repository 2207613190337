.layout-sider {
    position: fixed;
    background-color: #000;
    width: 280px;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    overflow-y: overlay;
    transition: all .5s wase-in;
}
.layout-sider.dark {
    background-color: #000;
}
.layout-sider.white {
    background-color: #fff;
    border-right: 1px solid #eee;
}
.layout-sider.inactive {
    width: 80px;
}
.layout-sider::-webkit-scrollbar {
    width: 5px;
}
.layout-sider::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 5px;
}
.layout-container {
    position: absolute;
    display: block;
    top:0;
    left: 280px;
    height: 100%;
    width: calc(100% - 280px);
    background: #fff;
    transition: all .2s ease-in;
}
.layout-container.inactive{
    left: 80px;
    width: calc(100% - 80px);
}
.layout-header {
    display: flex;
    justify-content: space-between;
    height: 64px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
.layout-content {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px;
}