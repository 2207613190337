.layout-sider .top-section {
    height: 50px;
    width: 100%;
    display: block;
    position: relative;
}
.layout-sider .logo {
    display: block;
    position: relative;
    text-decoration: none;
    height: 50px;
    width: 100%;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
}
.layout-sider.inactive .logo {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}
.layout-sider .logo img {
    vertical-align: top;
    display: inline-block;
    width: 45px;
    height: 45px;
    margin-right: 5px;
    overflow: hidden;
}
.layout-sider .logo span {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 30px;
}
.layout-sider.dark .logo span {
    color: #fff;
}
.layout-sider.white .logo span {
    color: #000;
}
.layout-sider .top-section .toggle-menu-btn {
    position: absolute;
    display: block;
    color: #fff;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    font-size: 23px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.layout-sider.dark .top-section .toggle-menu-btn {
    color: #fff;
}
.layout-sider.white .top-section .toggle-menu-btn {
    color: #000;
}
.layout-sider .search-controller {
    position: relative;
    display: block;
    height: 35px;
    margin: 20px 0;
}
.layout-sider .search-controller .search-icon {
    position: absolute;
    display: block;
    color: #fff;
    height: 35px;
    width: 35px;
    font-size:  16px;
    text-align: center;
    line-height: 35px;
}
.layout-sider .search-controller input {
    display: block;
    border: 0;
    outline: none;
    height: 100%;
    width: 100%;
    background: #333;
    color: #fff;
    border-radius: 5px;
    padding-left: 40px;
}
.divider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #333;
}
.layout-sider .sider-nav {
    margin-top: 20px;
    width: 100%;
}
.layout-sider .sider-nav .sider-nav-item {
    position: relative;
    display: block;
    text-decoration: none;
    color: #666;
    height: 40px;
    width: 100%;
    cursor: pointer;
    margin-top: 3px;
    overflow: hidden;
}
.layout-sider.dark .sider-nav-item .sider-nav-link {
    color: #eee;
}
.layout-sider.dark .sider-nav-item:hover .sider-nav-link {
    color: #11101d;
    background: #fff;
}
.layout-sider.white .sider-nav-item .sider-nav-link {
    color: #75838F;
    background: #fff;
}
.layout-sider.white .sider-nav-item:hover .sider-nav-link {
    background: #eee;
}
.layout-sider.white .sider-nav-item.active .sider-nav-link {
    color: #fff;
    background: #1A73E8;
}
.sider-nav-item .sider-nav-link {
    text-decoration: none;
    display: block;
    color: #eee;
    border-radius: 5px;
    align-items: center;
    transition: all .4s ease;
    overflow: hidden;
}
.sider-nav-item .sider-nav-link i {
    display: inline-block;
    position: relative;
    top: 5px;
    padding: 0 5px;
}
.sider-nav-item:hover .sider-nav-link {
    color: #11101d;
    background: #fff;
}
.sider-nav-item.active .sider-nav-link {
    background: #666;
    color: #fff;
}

.sider-nav-item .icon {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 23px;
    line-height: 40px;
    border-radius: 5px;
    vertical-align: top;
    text-align: center;
}
.sider-nav-item .sider-nav-link span {
    display: inline-block;
    position: relative;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
}
.layout-sider.inactive .sider-nav-item .sider-nav-link span {
    display: none;
    opacity: 0;
}
.layout-sider .sider-nav-item .sider-nav-tooltip{
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%);
    border-radius: 5px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: inherit;
    color: #eee;
    transition: 0s;
    opacity: 0;
    z-index: 99;
    pointer-events: none;
}
.layout-sider.inactive .sider-nav-item:hover .sider-nav-tooltip {
    transition: all 0.5s ease;
    top:50%;
    opacity: 0;
}
.layout-sider .main-menu .sub-menu {
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
    color: #666;
    overflow: hidden;
    max-height: 0;
}
.layout-sider .main-menu .sub-menu.active {
    max-height: 200px;
}
.layout-sider .main-menu .sub-item{
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    color: #666;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    padding: 5px;
}
.layout-sider .main-menu .sub-item.active {
    background: #666;
    color: #fff;
    border-radius: 5px;
}