.layout-header-name {
    display: inline-block;
    position: relative;
    height: 100%;
    font-size: 26px;
    line-height:64px;
    color: #000;
    left: 0;
    top: 0;
    padding-left: 50px;
    vertical-align: top;
}
.layout-header-right-container {
    display: inline-block;
    position: relative;
    padding-right: 50px;
}
.layout-header-right-container .user-profile {
    display: inline-block;
    position: relative;
    vertical-align: top;
    line-height: 63px;
}
.user-profile .avatar {
    top: 0;
    display: inline-block;
    position: relative;
    text-align: center;
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 50%;
    background-color: #ff0000;
    color: #fff;
}
.user-profile .user-info {
    display: inline-block;
    position: relative;
    font-size: 18px;
    padding-left: 10px;
}
.layout-header-right-container .icon-logout {
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: inherit;
    border: 0;
    margin-left: 30px;
    outline: none;
}
.layout-header-right-container .icon-logout {
    font-size: 23px;
    line-height: 64px;
}