.layout-login-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #0077be;
}
.login-form-container {
    width: 380px;
    height: 480px;
    position: relative;
    margin: 6% auto;
    background: #fff;
    padding: 5px;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 5px;
}
.login-form-container .login-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.login-form-container .login-title {
    font-size: 38px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
}
.login-input-checkbox {
    display: flex;
    margin: 10px 0;
    width: 100%;
    font-size: 12px;
}
.login-input-checkbox input{
    margin-right: 5px;
}
.login-input-checkbox label {
    color: #777;
}