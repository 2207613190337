.button {
    border-radius: 20px;
    border: 1px solid #5995fd;
    background: #5995fd;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}
.button:active{
    transform: scale(.95);
}
.button:focus{
    outline: none;
}
.button.ghost{
    background: transparent;
    border-color: #fff;
}